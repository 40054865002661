$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
/*    if ( breakpoint['medium'] ) {
        $('.page-navi').stick_in_parent({
            parent: 'body'
        });
    }*/

    $window = $(window);
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
            if (scroll >= 50) {
                $(".page-navi").addClass("is_stuck");
            }
            else {
                $(".page-navi").removeClass("is_stuck");
            }
    });

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
            if (scroll >= 480) {
                $(".speisekarte-btn,.reservierung-btn").css("background","#251206");
            }
            else {
                $(".speisekarte-btn,.reservierung-btn").css("background","none");
            }
    });

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
            if (scroll >= 200) {
                $(".teaser-inner").addClass("transform");
            }
            else {
                $(".teaser-inner").removeClass("transform");
            }
    });

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
            if (scroll >= 1900) {
                $(".porsche img").addClass("transform-porsche");
            }
            else {
                $(".porsche img").removeClass("transform-porsche");
            }
    });

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


/**
 * Navigation hover sliding with mouse
 */ 


var $el, leftPos, newWidth,
    $mainNav = $(".navi-main");

  $mainNav.append("<li id='line'></li>");
  var $magicLine = $("#line");

  $magicLine
    .width($(".active").width())
    .css("left", $(".active a").position().left)
    .data("origLeft", $magicLine.position().left)
    .data("origWidth", $magicLine.width());

  $(".navi-main li a").hover(function() {
    $el = $(this);
    leftPos = $el.position().left;
    newWidth = $el.parent().width();
    $magicLine.stop().animate({
      left: leftPos,
      width: newWidth
    });
  }, function() {
    $magicLine.stop().animate({
      left: $magicLine.data("origLeft"),
      width: $magicLine.data("origWidth")
    });
  });

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/